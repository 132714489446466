[data-amplify-authenticator] {
    --amplify-colors-background-primary: #5F1C73;
    --amplify-colors-background-secondary: #5F1C73;
    --amplify-colors-brand-primary-10: #5F1C73;
    --amplify-colors-brand-primary-80: #5F1C73;
    --amplify-colors-brand-primary-90: #5F1C73;
    --amplify-colors-brand-primary-100: #5F1C73;
    --amplify-colors-font-interactive: #5F1C73;
    --amplify-components-tabs-item-color: #999999;
    --amplify-components-tabs-item-active-color: #5F1C73;
    --amplify-components-tabs-item-active-border-color: #5F1C73;
    --amplify-components-tabs-item-focus-color: #5F1C73;
    --amplify-components-tabs-item-hover-color: #F59201;
    --amplify-components-authenticator-router-border-color: #000000;
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-router-border-style: solid;
    --amplify-components-button-link-color: #F59201;
    --amplify-components-authenticator-router-box-shadow: 0px 4px 12px var(--amplify-colors-shadow-primary);
}

[data-amplify-authenticator] [data-amplify-router] {
    -moz-border-radius-bottomleft: 10px;
    -moz-border-radius-bottomright: 10px;
}

[data-amplify-authenticator] .amplify-input {
    background-color: #ffffff;
}

.amplify-button[data-variation='primary'] {
    background: #5F1C73
}

/*
border-color: var(--amplify-components-authenticator-router-border-color);
border-width: var(--amplify-components-authenticator-router-border-width);
border-style: var(--amplify-components-authenticator-router-border-style);
 */